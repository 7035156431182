.backgroundHero {
  background-image: url("../assests/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */
}

.text_gradient {
  background: linear-gradient(
    to bottom,
    #01b0ea 0%,
    #01b0ea 53.3%,
    #01b0ea 50%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* for Safari/Chrome */
  background-clip: text;
  color: transparent; /* fallback text color */
}

.linearGradient {
  background-image: linear-gradient(
    to bottom,
    rgba(51, 51, 51, 1),
    rgba(27, 27, 27, 0)
  );
}
.logininput {
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  height: 48px;
  background: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", "Work Sans", "Helvetica";
}
