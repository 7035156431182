.linearGradient {
  background-image: linear-gradient(
    to bottom,
    rgba(51, 51, 51, 1),
    rgba(27, 27, 27, 0)
  );
  width: 350px;
  border: 1px solid rgba(255, 255, 255, 0.06);
}

.linearBorder {
  border: 1px solid rgba(255, 255, 255, 0.06);
}
.linearGradient2 {
  background-image: linear-gradient(
    to bottom,
    rgba(27, 27, 27, 0),
    rgba(27, 27, 27, 0),
    rgba(51, 51, 51, 1)
  );
  width: 350px;
}
.mainMarquee {
  overflow: hidden;
  display: flex;
}
.wrapper {
  position: relative;
}
.marquee {
  animation: marquee 40s linear infinite;
}

.marquee2 {
  animation: marquee2 40s linear infinite;
  position: absolute;
  top: 0;
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes marquee2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
